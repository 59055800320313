<template>
  <div v-if="entity">
    <h3>Nieuw eigenaarsrapport</h3>
    <div>
      <div>
        <div class="tw-mt-8">
          <h4>Eigenaars</h4>
          <div class="tw-flex tw-flex-wrap tw-gap-4">
            <div
              v-for="owner in owners"
              :key="owner.id"
              class="tw-w-64"
            >
              <input
                type="checkbox"
                :value="owner.id"
                @click="ownersSelected"
              >
              <router-link
                :to="{ name: 'ContactDetails', params: { id: owner.id } }"
                target="_blank"
              >
                <span class="tw-font-bold tw-ml-1">{{ owner.display_name }}</span>
              </router-link>
              <br />
              <span>{{ owner.email }}</span>
              <br />
              <address v-if="owner.city">
                {{ owner.street }} {{ owner.number }} {{ owner.box }}<br>
                {{ owner.city.zip_code }} {{ owner.city.name }}
              </address>
              <em>Taal: {{ owner.language }}</em>
            </div>
          </div>
          <h4 class="tw-mt-8">Opties van het rapport</h4>
          <div>
            <div>
              <div class="tw-flex tw-flex-wrap tw-gap-4 tw-mb-4">
                <div class="tw-w-64">
                  <h5>Communicatie opnemen sinds</h5>
                  <div>
                    <input
                      v-model="periodType"
                      name="period"
                      type="radio"
                      value="previous"
                    />
                    Vorig rapport
                  </div>
                  <div>
                    <input
                      v-model="periodType"
                      name="period"
                      type="radio"
                      value="start"
                    />
                    Opstart dossier
                  </div>
                </div>
                <div>
                  <h5>Soort rapport</h5>
                  <div>
                    <input
                      v-model="reportType"
                      name="type"
                      type="radio"
                      value="period"
                    />
                    Tussentijds rapport
                  </div>
                  <div>
                    <input
                      v-model="reportType"
                      name="type"
                      type="radio"
                      value="end"
                    />
                    Eindrapport
                  </div>
                  <div
                    v-if="reportType === 'end'" class="tw-mt-2"
                  >
                    <input
                      v-model="showColdlist"
                      type="checkbox"
                      value="end"
                    />
                    Leads uit coldlist opnemen
                    <div class="tw-mt-2">
                      <label class="tw-mr-2">Kostprijs originele stukken</label>
                      <input
                        v-model="priceOriginalPieces"
                        placeholder="Prijs"
                        step="0.01"
                        type="number"
                        lang="nl"
                        class="tw-border tw-border-gray-e9 tw-p-1 focus:tw-outline-none focus:tw-border-primary"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-mb-2 tw-w-full md:tw-w-1/2">
            <textarea
              v-model="comments"
              placeholder="Hier kan je optioneel extra uitleg kwijt die wordt opgenomen in het rapport."
              class="tw-border tw-border-gray-e9 tw-block tw-w-full tw-p-2 focus:tw-outline-none focus:tw-border-primary"
            />
            <div class="tw-grid md:tw-grid-cols-4 tw-gap-2 tw-mt-2">
              <button
                type="button"
                :disabled="!selectedOwners.length"
                class="btn btn-primary"
                @click="generateSample"
              >
                <i class="fa fa-laptop" /> Voorbeeld
              </button>
              <button
                type="button"
                :disabled="!previewGenerated"
                class="btn btn-default"
                @click="sendViaEmail"
              >
                <i class="fa fa-at" /> Versturen via e-mail
              </button>
              <button
                type="button"
                :disabled="!previewGenerated"
                class="btn btn-default"
                @click="sendViaPost"
              >
                <i class="fa fa-envelope" /> Versturen per post
              </button>
              <button
                type="button"
                :disabled="!previewGenerated"
                class="btn btn-default"
                @click="generateExamplePDF"
              >
                <i class="fa fa-eye" /> Voorbeeld PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="preview" />
  </div>
</template>

<script>
import $ from 'jquery'

import StoreIntegration from '@/store/integration'
import { mapActions } from 'vuex'
import { startLoadingModal, errorModal, successModal, questionModal, stopLoadingModal } from '@/modalMessages'
import { getOwnerReportPreview, emailOwnerReport } from '@/services/ownerReports'
import { getPropertyOwners } from '@/services/properties'
import { getProjectOwners } from '@/services/projects'
import { pollGutenborg } from '@/services/gutenborgService'
import { poll } from '@/utils/helpers'

export default {
  name: 'EntityOwnerReportFormOld',
  mixins: [StoreIntegration],
  data () {
    return {
      owners: [],
      periodType: 'previous',
      reportType: 'period',
      showColdlist: false,
      priceOriginalPieces: null,
      language: 'nl',
      previewGenerated: false,
      reportId: null,
      selectedOwners: [],
      comments: ''
    }
  },
  computed: {
    entity () {
      const payload = this.$route.params.id
      if (this.$route.name === 'PropertyOwnerReportForm') {
        const entityObj = this.getFromStore('properties/getPropertyById', payload, 'properties/loadProperty', payload)
        if (entityObj) {
          entityObj.entity_type = 'property'
        }
        return entityObj
      } else if (this.$route.name === 'ProjectOwnerReportForm') {
        const entityObj = this.getFromStore('properties/getProjectById', payload, 'properties/loadProject', payload)
        if (entityObj) {
          entityObj.entity_type = 'project'
        }
        return entityObj
      } else {
        return {}
      }
    }
  },
  created () {
    this.loadOwners()
  },
  methods: {
    ...mapActions('properties', [
      'createOwnerReportsForProperty',
      'createOwnerReportsForProject'
    ]),

    async loadOwners () {
      let response
      if (this.entity?.entity_type === 'property') {
        response = await getPropertyOwners(this.entity.id)
      } else if (this.entity?.entity_type === 'project') {
        response = await getProjectOwners(this.entity.id)
      }
      this.owners = response.data
      return response
    },
    ownersSelected (event) {
      if (event.target.checked) {
        this.selectedOwners.push(event.target.value)
      } else {
        this.selectedOwners.splice(this.selectedOwners.indexOf(event.target.value), 1)
      }
    },

    /**
     * Validate and generate the data for the owner report
     * @param sendByMail Boolean indicating if the report will be sent via email. This triggers additional validation
     *                  for the contact email address.
     * @returns Object containing the information for the owner report generation
     * @private
     */
    _validateAndGenerateData: function (sendByMail) {
      if (this.owners.length === 0) {
        errorModal('Je hebt geen eigenaar geselecteerd')
        return null
      }
      if (sendByMail) {
        let stopSend = false
        this.selectedOwners.forEach(ownerId => {
          const owner = this.owners.filter(o => {
            return o.id === parseInt(ownerId)
          })[0]
          if (!owner.email) {
            errorModal('Eén of meerdere van de geselecteerde eigenaars heeft geen e-mail adres. Eigenaarsrapport werd niet verzonden. ')
            stopSend = true
          }
        })
        if (stopSend) {
          return null
        }
      }
      return {
        entity: this.entity.id,
        owners: this.selectedOwners,
        example: true,
        period: this.periodType,
        type: this.reportType,
        coldlist_leads: this.showColdlist,
        price_original_pieces: this.priceOriginalPieces,
        language: this.language,
        comments: this.comments
      }
    },
    createPdf (reportId, postal) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: `/api/owner-reports/${reportId}/generate_pdf_via_gutenborg`,
          data: {
            postal: postal
          }
        }).done((data) => {
          resolve(data.job_id)
        }).fail((error) => {
          console.error(error)
          errorModal('Er is iets mis gegaan bij het aanmaken van het eigenaarsrapport.')
          reject(error)
        })
      })
    },
    finishPDFGeneration (url, reportId) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: `/api/owner-reports/${reportId}/finish_generation`,
          data: {
            url: url
          }
        }).done(() => {
          resolve()
        }).fail((error) => {
          console.error(error)
          errorModal('Er is iets mis gegaan bij het aanmaken van dit eigenaarsrapport.')
          reject(error)
        })
      })
    },
    async generateExamplePDF () {
      startLoadingModal('Het voorbeeld eigenaarsrapport wordt aangemaakt. Dit kan even duren')
      const errorMessage = 'Er ging iets mis bij het genereren van dit eigenaarsrapport.'
      try {
        const jobId = await this.createPdf(this.reportId, false)
        const data = await this.getData(jobId)
        const uuid = data[this.reportId]
        const pollResult = await poll(uuid, pollGutenborg, 1000)
        if (pollResult.url) {
          successModal('Het eigenaarsrapport voorbeeld is klaar.')
          const win = window.open(pollResult.url, '_blank')
          if (win) win.focus()
          else errorModal('Sta pop-ups toe om het eigenaarsrapport te zien.')
        } else {
          errorModal(errorMessage)
        }
      } catch (e) {
        console.error(e)
        errorModal(errorMessage)
      }
    },
    async generateOwnerReportPDFs (dataSets) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const urls = []
        try {
          for (const ownerReportId in dataSets) {
            // const url = await this.generatePDF(dataSets[ownerReportId])
            const pollResult = await poll(dataSets[ownerReportId], pollGutenborg, 1000)
            await this.finishPDFGeneration(pollResult.url, ownerReportId)
            urls.push(pollResult.url)
          }
        } catch (e) {
          console.error(e)
          reject(e)
        }
        resolve(urls)
      })
    },
    async getData (jobId, skipPDF = false) {
      return new Promise((resolve) => {
        let ownerReportId = null
        let dataSets = null
        let status = 0
        const poll = window.setInterval(async () => {
          // call the api conditionally
          if (!status) {
            // call the api
            const result = await this.pollIAM(jobId)
            status = result.status
            if (skipPDF && 'owner_report_ids' in result) {
              ownerReportId = result.owner_report_ids[0]
            } else if ('uuid_list' in result) {
              dataSets = result.uuid_list
            }
          } else {
            clearInterval(poll)
            this.isLoading = false
            if (status === 1) {
              if (!skipPDF) {
                resolve(dataSets)
              } else {
                const response = await getOwnerReportPreview(ownerReportId).catch(error => {
                  console.error(error)
                  errorModal('Er is iets mis gegaan bij het weergeven van het voorbeeld rapport.')
                })
                this.$refs.preview.innerHTML = response.data
                this.previewGenerated = true
                this.reportId = ownerReportId
                stopLoadingModal()
                resolve()
              }
            } else {
              errorModal('Er ging iets mis bij het genereren van het voorbeeld')
            }
          }
        }, 1000)
      })
    },
    async generateSample () {
      startLoadingModal('De gegevens voor het eigenaarsrapport worden verzameld...')
      this.$refs.preview.innerHTML = ''
      this.previewGenerated = false
      const data = this._validateAndGenerateData()
      if (!data) {
        return
      }
      data.skip_gutenborg = true
      const errorMessage = 'Er is iets mis gegaan bij het weergeven van het voorbeeld rapport.'

      // let ownerReport
      let ownerReportJob
      if (this.entity.entity_type === 'property') {
        ownerReportJob = await this.createOwnerReportsForProperty({
          data: data,
          entity: this.entity
        }).catch(error => {
          console.error(error)
          errorModal(errorMessage)
        })
      } else {
        ownerReportJob = await this.createOwnerReportsForProject({
          data: data,
          entity: this.entity
        }).catch(error => {
          console.error(error)
          errorModal(errorMessage)
        })
      }
      await this.getData(ownerReportJob.job_id, true)
    },
    async createOwnerReportJob (data) {
      let job
      if (this.entity.entity_type === 'property') {
        job = await this.createOwnerReportsForProperty({ data: data, entity: this.entity }).catch(error => {
          console.error(error)
          errorModal('Er is iets mis gegaan bij het aanmaken van het rapport.')
        })
      } else {
        job = await this.createOwnerReportsForProject({ data: data, entity: this.entity }).catch(error => {
          console.error(error)
          errorModal('Er is iets mis gegaan bij het aanmaken van het rapport.')
        })
      }
      return job
    },
    async sendViaEmail () {
      const result = await questionModal('Weet je zeker dat je het eigenaarsrapport wilt versturen?')
      if (result.value) {
        startLoadingModal('De gegevens voor het eigenaarsrapport worden verzameld...')
        const data = this._validateAndGenerateData(true)
        if (!data) {
          return
        }
        data.example = false
        const job = await this.createOwnerReportJob(data)
        const dataSets = await this.getData(job.job_id)
        await this.generateOwnerReportPDFs(dataSets).catch(e => {
          console.error(e)
          errorModal('Er ging iets mis bij het genereren van de eigenaarsrapporten, gelieve nogmaals te proberen')
        })

        const promises = []
        for (const ownerReportId in dataSets) {
          promises.push(emailOwnerReport(ownerReportId))
        }
        await Promise.all(promises).catch(error => {
          console.error(error)
          errorModal('Er is iets misgegaan bij het versturen van het rapport')
        })
        successModal('De e-mail werd verstuurd naar de eigenaars', true)
      }
    },
    async sendViaPost () {
      const result = await questionModal('Weet je zeker dat je het eigenaarsrapport wilt versturen?')
      if (result.value) {
        startLoadingModal('De gegevens voor het eigenaarsrapport worden verzameld...')
        const data = this._validateAndGenerateData()
        if (!data) {
          return
        }
        data.example = false
        data.postal = true

        const job = await this.createOwnerReportJob(data)
        const dataSets = await this.getData(job.job_id)
        const urls = await this.generateOwnerReportPDFs(dataSets)

        for (const url of urls) {
          window.open(url, '_blank')
        }

        successModal('Het eigenaarsrapport wordt gedownload.', true)
      }
    },
    pollIAM (jobId) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'get',
          url: `/api/owner-reports/jobs/${jobId}/detail`,
          contentType: 'application/json'
        }).done((data) => {
          resolve(data)
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
